
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Button from '~/components/elements/Button';
import ProductCardBadges from '~/components/catalog/productCard/Badges';
import ProductCardPosters from '~/components/catalog/productCard/Posters';
import CustomImage from '~/components/elements/CustomImage';

export default {
  name: 'ProductCard',
  components: {
    CustomImage,
    ProductCardPosters,
    ProductCardBadges,
    Button,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    pageType: {
      type: String,
      default: 'catalog',
    },
    tall: {
      type: Boolean,
      default: false,
    },
    minified: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    activeVideoProduct: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    ...mapGetters('currency', { getCurrency: 'getCurrency' }),
    ...mapGetters('cart', [
      'productInCart',
      'getProductCount',
      'getProductCountWithBundle',
    ]),
    ...mapGetters('catalog', ['formattedAllCategories']),
    ...mapGetters('favorites', [
      'productInFavorites',
    ]),
    ...mapState('auth', ['hasSession']),
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet' || this.$mq === 'laptop';
    },
    categoryLink() {
      const categoryCodes = this.item.categoryCodes;
      return (
        categoryCodes &&
        categoryCodes.length > 0 &&
        categoryCodes[categoryCodes.length - 1]
      );
    },
    cardBtn() {
      if (!this.item?.price?.value) {
        return {
          theme: 'simple',
          name: 'Товар закончился',
          icon: '',
          disabled: true,
          onClick: '',
        }
      }

      const isInCart = this.productInCart(this.item.id)

      return {
        name: '',
        theme: 'simple',
        disabled: false,
        icon: isInCart ? 'cat-cart-filled' : 'cat-cart',
        onClick: isInCart ? this.goToCart : this.onAddCartButtonClick,
      }
    },
    productPosters() {
      return this.item?.media || []
    },
    stockQty() {
      return this.item.stock.qty
    },
  },
  methods: {
    ...mapActions({
      addToCartProduct: 'cart/addToCartProduct',
      changeCartProductItemCount: 'cart/changeCartProductItemCount',
      addFavoritesItem: 'favorites/addFavoritesItem',
      deleteFavoritesItem: 'favorites/deleteFavoritesItem',
      showQuickProduct: 'quickLook/showQuickProduct',
    }),
    ...mapMutations('auth', {
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
    onAddCartButtonClick() {
      if (this.item.variantGroups) {
        this.showQuickProduct({ code: this.item.code, category: this.categoryLink })
        return
      }

      this.addItemToCart(this.item)
    },
    addItemToCart(item) {
      try {
        // eslint-disable-next-line no-undef
        rrApi.addToBasket(item.id);
      } finally {
        this.addToCartProduct(item);
      }
    },

    goToCart() {
      this.$router.push({ path: '/cart' })
    },
    goToProduct() {
      this.$router.push({
        name: 'catalog.category.product',
        params: {
          category: this.categoryLink,
          product: this.item.code,
        },
      })
    },
    styleColor(props) {
      const characteristics = this.item.variantGroups.characteristics;
      let color;
      props.forEach(itemProp => {
        const codeItem = itemProp.split('#')[0];
        const characteristicItem = characteristics.find(item => item.code === codeItem && item.isColor);
        if (characteristicItem) {
          color = itemProp.split('#')[1];
        }
      });

      return {
        backgroundColor: `#${color}`,
      };
    },
    toggleToFavoritesItem() {
      if (this.hasSession) {
        if (this.productInFavorites(this.item.productId)) {
          this.deleteFavoritesItem(this.item.productId);
        } else {
          this.addFavoritesItem(this.item.productId);
        }
        this.$emit('toggle-favorite', this.item.productId);
      } else {
        this.setIsOpenedAuthModal(true);
      }
    },
    removeFromPromo() {
      this.$emit('remove-item-from-promo', this.item.productId)
    },
  },
};
