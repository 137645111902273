
import {directive} from 'vue-awesome-swiper';
import CustomImage from '~/components/elements/CustomImage';
import ProductCardYoutubeVideo from '~/components/catalog/productCard/YoutubeVideo';

export default {
  name: 'ProductCardPosters',
  components: {
    ProductCardYoutubeVideo,
    CustomImage,
  },
  directives: {
    swiper: directive,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    productId: {
      type: [Number, String],
      required: true,
    },
    posters: {
      type: Array,
      required: true,
    },
    size: {
      type: String,
      default: 'small',
    },
    aspectRatio: {
      type: String,
      default: '100%',
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    activeVideoProduct: {
      type: [Number, String],
      default: null,
    },
    video: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      videoSlideIndex: 0,
      activeSlideIndex: 0,
      isVideoSlide: false,
    }
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobileXs';
    },
    sliderClass() {
      return `product-posters_${this.id}`
    },
    sliderOptions() {
      return {
        observer: true,
        observeParents: true,
        spaceBetween: 0,
        centeredSlides: false,
        freeMode: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        loop: false,
        preloadImages: false,
        allowTouchMove: this.mqIsMobile,
        pagination: {
          el: `.product-posters_${this.id}  .product-posters__dots`,
          clickable: false,
        },
      }
    },
    controls() {
      return this.getControlsArray(this.posters)
    },
  },
  watch: {
    activeVideoProduct() {
      if (this.activeVideoProduct !== this.productId) {
        this.slideToIndex(0)
      }
    },
    activeSlideIndex() {
      this.isVideoSlide = this.activeSlideIndex === this.videoSlideIndex

      this.$emit('videoStateChange', { id: this.productId, state: this.isVideoSlide })
    },
  },
  mounted() {
    if (!this.sliderPosters) return

    this.sliderPosters.on('realIndexChange', () => {
      this.activeSlideIndex = this.sliderPosters.activeIndex
    })
  },
  methods: {
    slideToIndex(id) {
      if (!this.sliderPosters) return

      this.sliderPosters.slideTo(id, 0)
    },
    getControlsArray(posters) {
      const controls = posters.map((_, index) => index)

      if (this.video) {
        this.videoSlideIndex = controls.length
        controls.push(controls.length)
      }

      return controls
    },
  },

}
