
import ProductCard from '~/components/catalog/productCard';
export default {
  name: 'ProductGrid',
  components: {ProductCard},
  props: {
    title: {
      type: String,
      default: '',
    },
    products: {
      type: Array,
      required: true,
    },
  },
}
